// Generated by Framer (acd6bc0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {yE983zJoa: {hover: true}};

const serializationHash = "framer-MBh0I"

const variantClassNames = {yE983zJoa: "framer-v-5yz8uw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({fontSize, height, id, link, newTab, title, width, ...props}) => { return {...props, DcVq_MkI9: link ?? props.DcVq_MkI9, Ob8gDgXQd: newTab ?? props.Ob8gDgXQd, WpTyBZKwh: title ?? props.WpTyBZKwh ?? "Here to Help to Available®", yIxC0HPnb: fontSize ?? props.yIxC0HPnb ?? 20} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;fontSize?: number;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WpTyBZKwh, yIxC0HPnb, DcVq_MkI9, Ob8gDgXQd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "yE983zJoa", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={DcVq_MkI9} openInNewTab={Ob8gDgXQd} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-5yz8uw", className, classNames)} framer-16odvcj`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yE983zJoa"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"yE983zJoa-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UGx1cyBKYWthcnRhIFNhbnMtcmVndWxhcg==", "--framer-font-family": "\"Plus Jakarta Sans\", \"Plus Jakarta Sans Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-yIxC0HPnb-Z8NisUAfG) * 1px)"}}>Here to Help to Available®</motion.p></React.Fragment>} className={"framer-ztei6a"} fonts={["GF;Plus Jakarta Sans-regular"]} layoutDependency={layoutDependency} layoutId={"K7ce9klkA"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-yIxC0HPnb-Z8NisUAfG": yIxC0HPnb}} text={WpTyBZKwh} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-1xc8q38"} data-framer-name={"Arrow"} fill={"black"} intrinsicHeight={17} intrinsicWidth={17} layoutDependency={layoutDependency} layoutId={"DwTX8Nyau"} svg={"<svg width=\"17\" height=\"17\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M8.5 16 16 8.5 8.5 1M1 8.5h15\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MBh0I.framer-16odvcj, .framer-MBh0I .framer-16odvcj { display: block; }", ".framer-MBh0I.framer-5yz8uw { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-MBh0I .framer-ztei6a { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-MBh0I .framer-1xc8q38 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 17px); position: relative; width: 17px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MBh0I.framer-5yz8uw { gap: 0px; } .framer-MBh0I.framer-5yz8uw > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-MBh0I.framer-5yz8uw > :first-child { margin-left: 0px; } .framer-MBh0I.framer-5yz8uw > :last-child { margin-right: 0px; } }", ".framer-MBh0I.framer-v-5yz8uw.hover.framer-5yz8uw { gap: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MBh0I.framer-v-5yz8uw.hover.framer-5yz8uw { gap: 0px; } .framer-MBh0I.framer-v-5yz8uw.hover.framer-5yz8uw > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-MBh0I.framer-v-5yz8uw.hover.framer-5yz8uw > :first-child { margin-left: 0px; } .framer-MBh0I.framer-v-5yz8uw.hover.framer-5yz8uw > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 272
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PPZtTR1Hl":{"layout":["auto","auto"]}}}
 * @framerVariables {"WpTyBZKwh":"title","yIxC0HPnb":"fontSize","DcVq_MkI9":"link","Ob8gDgXQd":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZ8NisUAfG: React.ComponentType<Props> = withCSS(Component, css, "framer-MBh0I") as typeof Component;
export default FramerZ8NisUAfG;

FramerZ8NisUAfG.displayName = "Arrow CTA";

FramerZ8NisUAfG.defaultProps = {height: 24, width: 272};

addPropertyControls(FramerZ8NisUAfG, {WpTyBZKwh: {defaultValue: "Here to Help to Available®", displayTextArea: false, title: "Title", type: ControlType.String}, yIxC0HPnb: {defaultValue: 20, title: "Font Size", type: ControlType.Number}, DcVq_MkI9: {title: "Link", type: ControlType.Link}, Ob8gDgXQd: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerZ8NisUAfG, [{explicitInter: true, fonts: [{family: "Plus Jakarta Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NTxXUEKi4Rw.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})